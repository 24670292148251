import { Box, Typography, Checkbox } from "@mui/material";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { TextField, Button } from "@mui/material";
// import ReCaptchaV2 from "react-google-recaptcha";
import { Link, useLocation } from "react-router-dom";
import DOMPurify from "dompurify";

// import phoneimg from "../../Assets/Icons/phoneimg.svg";
// import emailimg from "../../Assets/Icons/emailimg.svg";
import { getMethodApi, postMethodApi } from "../../Utils/Methods";
import {
  GET_CONFIG,
  GET_OFFICE_LOCATION,
  POST_CONTACT_US,
} from "../../Apis/EndPoints";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
// import Captcha from "../../Components/Captcha/Captcha";

// const contactUs = [{ icon: phoneimg }, { icon: emailimg }, { icon: emailimg }];

export default function ContactUsLandingKorean() {
  const navigate = useNavigate();
  // const [aboutUs, setAboutUs] = useState([]);
  const [location, setlocation] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [seoData, setSeoData] = useState("");
  const [loader2, setLoader2] = useState(true);
  const [setTimeOutLoader, setSetTimeOutLoader] = useState(true);
  const [contactInfo, setContactInfo] = useState({
    name: "",
    Company: "",
    Email: "",
    Message: "",
    Phone: "",
    ip_address: "",
    checkboxCookie: false,
    page_name: window.location.href.split("/").slice(-1)[0],
  });
  const locationLink = useLocation();
  const getInTouch = useRef();

  useEffect(() => {
    setTimeout(() => {
      let queryParam = locationLink?.pathname?.split("contactus/")[1];
      if (queryParam === "getintouch") {
        getInTouch.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }, 500);
  }, [locationLink?.pathname]);

  useEffect(() => {
    getMethodApi(GET_CONFIG).then((response) => {
      if (response.status === 200) {
        setSeoData(response.data);
        setLoader(false);
        setLoader2(false);
      }
    });
    getMethodApi(GET_OFFICE_LOCATION).then((response) => {
      if (response.status === 200) {
        setlocation(response.data);
        setLoader1(false);
      }
    });

    setTimeout(() => {
      setSetTimeOutLoader(false);
    }, 2000);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (event) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setContactInfo({
      ...contactInfo,
      [event.target.name]: sanitizedValue,
    });
  };

  const createContactInfo = () => {
    contactInfo.ip_address = localStorage.getItem("IPAddress");

    if (
      !contactInfo.Email.split("").includes("@") ||
      !contactInfo.Email.split("").includes(".") ||
      contactInfo.Email.includes(" ")
    ) {
      toast.error("올바른 이메일을 입력하세요");
      return;
    }

    if (
      contactInfo.checkboxCookie === "" ||
      contactInfo.checkboxCookie === undefined ||
      contactInfo.checkboxCookie === false
    ) {
      toast.error("이용약관에 동의해주세요 ");
      return;
    }

    postMethodApi(POST_CONTACT_US, contactInfo)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Contact Information is sent ");
          navigate("/thank-you");
          setContactInfo({
            name: "",
            Company: "",
            Email: "",
            Phone: "",
            checkboxCookie: false,
          });
        }
        if (res.status === 400) {
          toast.error(res.data?.message);
        }
      })
      .catch((error) => {
        toast.error("Some error occur ");
        return;
      });
  };

  return (
    <>
      {/* Seo */}
      <Helmet>
        <title>{"문의하기 | Indxx"}</title>
        <meta
          name="description"
          content={
            seoData?.Contact_us_Description
              ? seoData.Contact_us_Description[0].value
              : "description"
          }
        />
        <meta
          name="keywords"
          content={
            seoData?.Contact_us_Keywords
              ? seoData.Contact_us_Keywords[0].value
              : "keys"
          }
        />
      </Helmet>
      <Box className="contactusbannerimgKorean DF FFC JCC AIC">
        <Typography
          variant={"h1"}
          className="contactusbannerimgparaKorean W90 TAC"
        >
          {"인덱스의 새로운 패러다임"}
        </Typography>
        <Typography
          variant={"h1"}
          className="contactusbannerimgparaKorean2 W90 TAC"
        >
          {"끊임없이 변화하는 투자 환경에서 고객의 성공을 위해,"}
        </Typography>
        <Typography
          variant={"h1"}
          className="contactusbannerimgparaKorean2 W90 TAC"
        >
          {"Indxx는 인덱스를 새롭게 정의합니다."}
        </Typography>
      </Box>

      <Box>
        <Box component={"div"} className="container MT60">
          <Box
            component={"div"}
            className="getintouchContainer DF JCC Fgap27 mobAIC MT4REM verySmallMobMT30"
          >
            <Box
              // sx={{ width: "100%" }}
              className="boxshadow4sides bordr10px getintouchform W80"
            >
              <Box sx={{ p: 3.26 }}>
                <Typography variant={"h1"} className="contactusformheading">
                  {"문의사항"}
                </Typography>

                <Box
                  sx={{ mt: 3.25 }}
                  className="DF JCSB FWW getintouchinputfield"
                >
                  <TextField
                    name="name"
                    label="성명"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    id="linehtN"
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                    }}
                    onChange={handleChange}
                    value={contactInfo.name}
                  />

                  <TextField
                    name="Company"
                    label="회사명"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    id="linehtN"
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                    }}
                    onChange={handleChange}
                    value={contactInfo.Company}
                  />

                  <TextField
                    name="Email"
                    label="이메일*"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    id="linehtN"
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                    }}
                    onChange={handleChange}
                    value={contactInfo.Email}
                  />

                  <TextField
                    ref={getInTouch}
                    name="Phone"
                    label="전화번호"
                    type="number"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    id="linehtN"
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 15);
                    }}
                    onChange={handleChange}
                    value={contactInfo.Phone}
                  />
                </Box>

                <TextField
                  name="Message"
                  label="내용"
                  className="accordionbggrey textfieldstylefull"
                  id="outlined-multiline-static linehtN"
                  multiline
                  rows={4}
                  size="small"
                  sx={{ height: 100, color: "#636363" }}
                  InputProps={{
                    style: {
                      height: "100px",
                      color: "#636363",
                    },
                  }}
                  onChange={handleChange}
                  value={contactInfo.Message}
                />

                {/* <Box className="reCaptcha">
                  <ReCaptchaV2
                    // sitekey={"6LfoBRUgAAAAAL6e1-thG56JCxpCRfDs_r5zoDry"}
                    sitekey={"6Ld5c_UoAAAAAHqS4gazOrVdYHcxJ6t5K-9MfKfY"}
                    // onChange={handleToken}
                    // onExpire={handleExpire}
                    hl='ko'
                    className="MB20"
                    ref={captchaRef}
                  />
                </Box> */}

                <Box
                  component={"div"}
                  className="DF AIFS MB20 verySmallMobMB10"
                >
                  <Checkbox
                    style={{ padding: "2px 1rem 0px 0rem" }}
                    className=""
                    name="checkboxCookie"
                    defaultChecked={false}
                    value={contactInfo.checkboxCookie}
                    onChange={handleChange}
                  ></Checkbox>
                  <Typography className="ContactustermsofusageKorea linehtN colorBlack">
                    {"본인은 당사의 이용약관을 수락하고, "}
                    <Link to={"/data-privacy"} className="colorBlack">
                      {"정보보호정책"}
                    </Link>
                    {"을 인지하며, 안내를 받는 것에 동의합니다."}
                  </Typography>
                </Box>

                <Button
                  className="sendmsgBtn mobMT20 mobMAuto linehtN"
                  onClick={createContactInfo}
                >
                  {"등록"}
                </Button>
                <Typography className="MT10">*필수</Typography>
              </Box>
            </Box>
          </Box>

          <Box className="DF JCC AIC MT40">
            <Typography variant="" className="fs22 W80">
              추가정보는{" "}
              <Typography variant="" className="fs22 fw600">
                marketing@indxx.com
              </Typography>{" "}
              으로 요청해주세요.
            </Typography>
          </Box>

          {location?.length > 0 ? (
            <Typography variant={"h3"} className="contactuslocationheading">
              {"Office Locations"}
            </Typography>
          ) : (
            ""
          )}

          <Box className="DF FWW FGap2R mobJCC mobFGap0">
            {location.map((continents, i) => (
              <Box className="MT60 mobMT30" key={i}>
                <Box className="locationBtn mobMT20 mobMAuto DF AIC JCC PL10 PR10">
                  {Object.keys(continents)}
                </Box>
                <Box className={`locationbox DF FWW FGap2R JCFS mobJCC`}>
                  {Object.values(continents).map((item, i) => {
                    return item.map((city, ind) => (
                      <Box className="DF FFC" key={ind}>
                        <img
                          className="locationimg"
                          src={city.Image}
                          alt="locationimg"
                        />
                        <Typography variant={"p"} className="locationimgtext">
                          {city.city}
                        </Typography>
                      </Box>
                    ));
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {setTimeOutLoader || loader || loader1 || loader2 ? <Loader /> : ""}
    </>
  );
}
