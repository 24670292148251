import { Box, Typography, Checkbox } from "@mui/material";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { TextField, Button } from "@mui/material";
// import ReCaptchaV2 from "react-google-recaptcha";
import { Link, useLocation } from "react-router-dom";
import DOMPurify from "dompurify";

// import phoneimg from "../../Assets/Icons/phoneimg.svg";
// import emailimg from "../../Assets/Icons/emailimg.svg";
import { getMethodApi, postMethodApi } from "../../Utils/Methods";
import {
  GET_CONFIG,
  GET_OFFICE_LOCATION,
  POST_CONTACT_US,
} from "../../Apis/EndPoints";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import useStyles from "../../Assets/Styles/Common/Common";

// const contactUs = [{ icon: phoneimg }, { icon: emailimg }, { icon: emailimg }];

export default function ContactUsLandingHerbrew() {
  const navigate = useNavigate();
  // const [aboutUs, setAboutUs] = useState([]);
  const [location, setlocation] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [seoData, setSeoData] = useState("");
  const [loader2, setLoader2] = useState(true);
  const [setTimeOutLoader, setSetTimeOutLoader] = useState(true);
  const [contactInfo, setContactInfo] = useState({
    name: "",
    Company: "",
    Email: "",
    Message: "",
    Phone: "",
    ip_address: "",
    checkboxCookie: false,
    page_name: window.location.href.split("/").slice(-1)[0],
  });
  const locationLink = useLocation();
  const getInTouch = useRef();
  const { customTextField } = useStyles();

  useEffect(() => {
    setTimeout(() => {
      let queryParam = locationLink?.pathname?.split("contactus/")[1];
      if (queryParam === "getintouch") {
        getInTouch.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }, 500);
  }, [locationLink?.pathname]);

  useEffect(() => {
    getMethodApi(GET_CONFIG).then((response) => {
      if (response.status === 200) {
        // setAboutUs(response.data["Contact Us"]);
        setSeoData(response.data);
        setLoader(false);
        setLoader2(false);
      }
    });
    getMethodApi(GET_OFFICE_LOCATION).then((response) => {
      if (response.status === 200) {
        setlocation(response.data);
        setLoader1(false);
      }
    });

    setTimeout(() => {
      setSetTimeOutLoader(false);
    }, 2000);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (event) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setContactInfo({
      ...contactInfo,
      [event.target.name]: sanitizedValue,
    });
  };

  const createContactInfo = () => {
    contactInfo.ip_address = localStorage.getItem("IPAddress");

    if (
      !contactInfo.Email.split("").includes("@") ||
      !contactInfo.Email.split("").includes(".") ||
      contactInfo.Email.includes(" ")
    ) {
      toast.error("הזן אימייל מתאים");
      return;
    }

    if (
      contactInfo.checkboxCookie === "" ||
      contactInfo.checkboxCookie === undefined ||
      contactInfo.checkboxCookie === false
    ) {
      toast.error("נא לקבל את תנאי השימוש ");
      return;
    }

    postMethodApi(POST_CONTACT_US, contactInfo)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Contact Information is sent ");
          navigate("/thank-you");
          setContactInfo({
            name: "",
            Company: "",
            Email: "",
            Phone: "",
            checkboxCookie: false,
          });
        }
        if (res.status === 400) {
          toast.error(res.data?.message);
        }
      })
      .catch((error) => {
        toast.error("Some error occur ");
        return;
      });
  };

  return (
    <>
      {/* Seo */}
      <Helmet>
        <title>{`עִברִית | Indxx`}</title>
        <meta
          name="description"
          content={
            seoData?.Contact_us_Description
              ? seoData.Contact_us_Description[0].value
              : "description"
          }
        />
        <meta
          name="keywords"
          content={
            seoData?.Contact_us_Keywords
              ? seoData.Contact_us_Keywords[0].value
              : "keys"
          }
        />
        {/* <!-- Google tag (gtag.js) --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11227532745"
        />
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-11227532745');
        `}
        </script>
      </Helmet>
      <Box className="contactusbannerimgHebrewbanner DF FFC JCC AIC">
        <Typography
          variant={"h1"}
          className="contactusbannerimgparaHebrew W90 TAC"
        >
          {"יצירת קשר"}
        </Typography>
        <Typography variant={"h2"} className="contactusbannerimgpara2 W90 TAC">
          {"פנו אלינו לקבלת פתרונות מדד מותאמים אישית"}
        </Typography>
      </Box>

      <Box>
        <Box component={"div"} className="container MT60">
          <Box className="DF JCC AIC ">
            <Typography variant="" className="fs26 W80 TAR">
              למידע נוסף, שלחו הודעה לכתובת
              <Typography variant="" className="fs26 fw600">
                {" "}
                marketing@indxx.com
              </Typography>
            </Typography>
          </Box>
          <Box
            component={"div"}
            className="getintouchContainer DF JCC Fgap27 mobAIC MT4REM verySmallMobMT30"
          >
            <Box
              // sx={{ width: "100%" }}
              className="boxshadow4sides bordr10px getintouchform W80"
            >
              <Box sx={{ p: 3.26 }}>
                <Typography
                  variant={"h1"}
                  className="contactusformheadingHebrew TAR"
                >
                  {"צרו קשר"}
                </Typography>

                <Box
                  sx={{ mt: 3.25 }}
                  className="DF JCSB FWW getintouchinputfield"
                >
                  <TextField
                    name="Company"
                    label="חברה"
                    className={`accordionbggrey textfieldstyle ${customTextField}`}
                    sx={{ color: "#636363" }}
                    id="linehtN"
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                        fontSize: "18px",
                      },
                    }}
                    inputProps={{
                      sx: {
                        textAlign: "right !important",
                      },
                    }}
                    onChange={handleChange}
                    value={contactInfo.Company}
                  />

                  <TextField
                    name="name"
                    label="שם"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    id="linehtN"
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                        fontSize: "18px",
                      },
                    }}
                    inputProps={{
                      sx: {
                        textAlign: "right !important",
                      },
                    }}
                    onChange={handleChange}
                    value={contactInfo.name}
                  />

                  <TextField
                    ref={getInTouch}
                    name="Phone"
                    label="טלפון"
                    type="number"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    id="linehtN"
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                        fontSize: "18px",
                      },
                    }}
                    inputProps={{
                      sx: {
                        textAlign: "right !important",
                      },
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 15);
                    }}
                    onChange={handleChange}
                    value={contactInfo.Phone}
                  />

                  <TextField
                    name="Email"
                    label={`דוא"ל`}
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    id="linehtN"
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                        fontSize: "18px",
                      },
                    }}
                    inputProps={{
                      sx: {
                        textAlign: "right !important",
                      },
                    }}
                    onChange={handleChange}
                    value={contactInfo.Email}
                  />
                </Box>

                <TextField
                  name="Message"
                  label="הודעה"
                  className="accordionbggrey textfieldstylefull"
                  id="outlined-multiline-static linehtN"
                  multiline
                  rows={4}
                  size="small"
                  sx={{ height: 100, color: "#636363" }}
                  InputProps={{
                    style: {
                      height: "100px",
                      color: "#636363",
                      fontSize: "18px",
                    },
                  }}
                  inputProps={{
                    sx: {
                      textAlign: "right !important",
                      fontSize: "18px",
                    },
                  }}
                  onChange={handleChange}
                  value={contactInfo.Message}
                />

                {/* <Box className="reCaptcha DF JCFE">
                  <ReCaptchaV2
                    // sitekey={"6LfoBRUgAAAAAL6e1-thG56JCxpCRfDs_r5zoDry"}
                    sitekey={"6Ld5c_UoAAAAAHqS4gazOrVdYHcxJ6t5K-9MfKfY"}
                    // lang="fr"
                    hl='iw'
                    // onChange={handleToken}
                    // onExpire={handleExpire}
                    className="MB20"
                    ref={captchaRef}
                  />
                </Box> */}

                <Box
                  component={"div"}
                  className="DF AIC JCFE MB20 verySmallMobMB10"
                >
                  <Typography className="ContactustermsofusageHebrew linehtN colorBlack">
                    {"אני מקבל/ת את תנאי השימוש של Indxx, מאשר/ת את מדיניות "}
                    <Link to={"/data-privacy"} className="colorBlack">
                      {"פרטיות המידע"}
                    </Link>
                    {" ומאשר/ת ליצור עימי קשר"}
                  </Typography>
                  <Checkbox
                    style={{ padding: "2px 0rem 0px 1rem" }}
                    className=""
                    name="checkboxCookie"
                    defaultChecked={false}
                    value={contactInfo.checkboxCookie}
                    onChange={handleChange}
                  ></Checkbox>
                </Box>

                <Box className="DF JCFE">
                  <Button
                    className="sendmsgBtn mobMT20 mobMAuto linehtN"
                    onClick={createContactInfo}
                  >
                    {"שליחה"}
                  </Button>
                </Box>
                <Box className="DF JCFE">
                  <Typography className="MT10">*חִיוּנִי</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {location?.length > 0 ? (
            <Typography variant={"h3"} className="contactuslocationheading">
              {"Office Locations"}
            </Typography>
          ) : (
            ""
          )}

          <Box className="DF FWW FGap2R mobJCC mobFGap0">
            {location.map((continents, i) => (
              <Box className="MT60 mobMT30" key={i}>
                <Box className="locationBtn mobMT20 mobMAuto DF AIC JCC PL10 PR10">
                  {Object.keys(continents)}
                </Box>
                <Box className={`locationbox DF FWW FGap2R JCFS mobJCC`}>
                  {Object.values(continents).map((item, i) => {
                    return item.map((city, ind) => (
                      <Box className="DF FFC" key={ind}>
                        <img
                          className="locationimg"
                          src={city.Image}
                          alt="locationimg"
                        />
                        <Typography variant={"p"} className="locationimgtext">
                          {city.city}
                        </Typography>
                      </Box>
                    ));
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {setTimeOutLoader || loader || loader1 || loader2 ? <Loader /> : ""}
    </>
  );
}
