import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { TextField, Button } from "@mui/material";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import { postMethodApi } from "../../Utils/Methods";
import { POST_VERIFY_USER } from "../../Apis/EndPoints";

export default function Forgot({
  toggle,
  setToggle,
  forgotScreen,
  setLoginScreen,
  setSignUpScreen,
  setForgotScreen,
  setOtpScreen,
  setforgotAndCreateHeading,
  onFinishForgotPassword,
}) {
  const [forgotPassword, setForgotPassword] = useState({
    emailid: "",
  });

  const handleChange = (event) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setForgotPassword({
      ...forgotPassword,
      [event.target.name]: sanitizedValue,
    });
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (
      email.trim().length > 0 &&
      emailRegex.test(email) &&
      !email.includes(",") &&
      !email.includes(" ")
    );
  }

  const createContactInfo = () => {
    if (!isValidEmail(forgotPassword.emailid)) {
      toast.error("Enter Correct Email Address ");
      return;
    }

    // if (forgotPassword.emailid.trim() === "") {
    //   toast.error("Email is required ");
    //   return;
    // }

    // if (
    //   !forgotPassword.emailid.split("").includes("@") ||
    //   !forgotPassword.emailid.split("").includes(".") ||
    //   forgotPassword.emailid.includes(" ")
    // ) {
    //   toast.error("Enter Correct Email Address ");
    //   return;
    // }

    postMethodApi(POST_VERIFY_USER, forgotPassword)
      .then((res) => {
        if (res.status === 200) {
          onFinishForgotPassword(forgotPassword);
          toast.success("OTP has been sent.");
          setSignUpScreen(false);
          setForgotScreen(false);
          setLoginScreen(false);
          setOtpScreen(true);
          setForgotPassword({
            emailid: "",
          });
        }
        if (res.status === 400) {
          toast.error(res.data?.message);
        }
      })
      .catch((error) => {
        toast.error("Some error occur ");
        return;
      });
  };

  return (
    <Box
      className="loginContainer DF JCC AIC"
      display={toggle && forgotScreen ? "" : "none"}
    >
      <Box className="boxshadow4sides bordr10px loginForm P2 PB1">
        <Box>
          <Box className="DF JCSB">
            <Typography variant={"h1"} className="contactusformheading">
              {"Forget Password"}
            </Typography>
            <Box
              className="cursorPointer"
              onClick={() => {
                setForgotPassword({
                  emailid: "",
                });
                setToggle(false);
                setForgotScreen(false);
              }}
            >
              <CloseIcon />
            </Box>
          </Box>
          <Typography variant={"h6"} className="loginTextSubHeading">
            {"Enter your registered email address to receive OTP"}
          </Typography>

          <Box sx={{ mt: 3.25 }} className="DF JCSB FFC getintouchinputfield">
            <TextField
              name="emailid"
              label="Email"
              className="accordionbggrey LoginInputs"
              sx={{ color: "#636363" }}
              size="small"
              InputProps={{
                style: {
                  height: "50px",
                  color: "#636363",
                },
              }}
              onChange={handleChange}
              value={forgotPassword.emailid}
            />
          </Box>

          {/* <MuiOtpInput value={otp} onChange={handleChangeOTP} className="MB1"/> */}

          <Button
            className="sendmsgBtnLogin mobMT20 mobMAuto linehtN"
            onClick={createContactInfo}
          >
            {"Send OTP"}
          </Button>
          <Box className="DF JCSB PT10">
            <Box
              className="cursorPointer hoverUnderline"
              onClick={() => {
                setForgotPassword({
                  emailid: "",
                });
                setSignUpScreen("");
                setForgotScreen("");
                setLoginScreen(true);
              }}
            >
              Back to Login
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
