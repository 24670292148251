import createUseContext from "constate";
import CognitoService from "../Utils/cognitoHelper";
import AuthReducer, { AuthInitialState } from "../reducers/auth";
import { logout, authSuccess, authFailure } from "../actions/auth";
import IdleTimer from "../Utils/IdleTimer";
import { useLocallyPersistedReducer } from "../Utils/persistent-reducer";
import { useEffect } from "react";

const onRefresh = async () => {
  try {
    const res = await CognitoService.refreshToken();

    localStorage.setItem("GadToken", res["idToken"]);
  } catch (error) {}
};
const onLogin = async (dispatch, email, password) => {
  try {
    const res = await CognitoService.login({ email, password });
    const token = res.accessToken["jwtToken"];
    const token2 = res.idToken["jwtToken"];
    const isProfileQA = res["idToken"].payload.profile === "QC";
    const isProfileAnalyst = res["idToken"].payload.profile === "Analyst";
    const isProfileAdmin = res["idToken"].payload.profile === "Admin";
    localStorage.setItem("role", res["idToken"].payload.profile);
    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
    localStorage.setItem("Name", res["idToken"].payload.name);
    localStorage.setItem("userID", res["accessToken"].payload.username);
    localStorage.setItem("AccessToken", token);
    localStorage.setItem("GadToken", token2);
    localStorage.setItem("SessionExpired", false);
    localStorage.setItem("RefreshToken", res.refreshToken["token"]);

    function displayAlert() {
      CognitoService.refreshsession();
    }

    setInterval(displayAlert, 60000 * 59);

    dispatch(
      authSuccess({
        user: res,
        token,
        isAdmin: isProfileAdmin,
        isQA: isProfileQA,
        isAnalyst: isProfileAnalyst,
      })
    );
  } catch (error) {
    dispatch(authFailure({ error: error.message }));
  }
};

const onLogout = async (dispatch) => {
  try {
    // eslint-disable-next-line
    const res = await CognitoService.logout();
    localStorage.clear();
    dispatch(logout());
    window.location.herf = "";
  } catch (error) {}
};

const onResetError = (dispatch) => {
  dispatch(authFailure({ error: null }));
};

const useAuth = () => {
  const [state, dispatch] = useLocallyPersistedReducer(
    AuthReducer,
    AuthInitialState,
    "auth"
  );

  useEffect(() => {
    if (state.token !== null) {
      const timer = new IdleTimer({
        timeout: 3600,
        onTimeout: async () => {
          onLogout(dispatch);
        },
        onExpired: () => {
          onRefresh();
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
    // eslint-disable-next-line
  }, [state.token]);

  const MINUTE_MS = 840000;

  useEffect(() => {
    const interval = setInterval(() => {
      onRefresh();
    }, MINUTE_MS);
    return () => clearInterval(interval);
  });

  const events = {
    login: (...args) => onLogin(dispatch, ...args),
    logout: (...args) => onLogout(dispatch, ...args),
    refreshToken: (...args) => onRefresh(...args),
    resetError: () => onResetError(dispatch),
  };

  return { ...state, ...events };
};

const [AuthProvider, UseAuthContext] = createUseContext(useAuth);

export { AuthProvider, UseAuthContext };
