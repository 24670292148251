import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  // CognitoIdentityServiceProvider,
  CognitoRefreshToken,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
let userPool;

const setUserPool = () => {
  if (!userPool) {
    const userPoolId = localStorage.getItem("userPoolId");
    const clientId = localStorage.getItem("clientId");
    userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    });
  }
};

const registerUser  = ({ email, password }) => {
  setUserPool();
  const attributeList = [];
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  const dataEmail = {
    Name: 'email',
    Value: email,
  };
  const attributeEmail = new CognitoUserAttribute(dataEmail);

  attributeList.push(attributeEmail);

  return new Promise((resolve, reject) => {
    cognitoUser.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        reject(err);
      } else {
        // Store the email in localStorage for use in the confirmation step
        localStorage.setItem('newEmail', email);
        resolve(result);
      }
    });
  });
};

const login = ({ email, password }) => {
  setUserPool();
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  });

  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });

  return new Promise((success, error) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        success(result);
      },

      onFailure: (err) => {
        error(err);
      },
    });
  });
};

const setNewPassword = async ({ oldPassword, newPassword }) => {
  const cognitoUser = getCurrentUser();

  return new Promise((success, error) => {
    cognitoUser.getSession(function (err, session) {
      if (err) {
        error(err.message);
        return err.message;
      }
      cognitoUser.changePassword(
        oldPassword,
        newPassword,
        function (err, result) {
          if (err) {
            error(err.message);
            return err.message;
          }
          success(result);
        }
      );
    });
  });
};

const confirmNewForgotPassword = ({ otp, newPassword }) => {
  setUserPool();
  const cognitoUser = new CognitoUser({
    Username: localStorage.getItem("forgotEmail"),
    Pool: userPool,
  });

  return new Promise((success, error) => {
    cognitoUser.confirmPassword(otp, newPassword, {
      onSuccess: (result) => {
        success(result);
      },

      onFailure: (err) => {
        error(err);
      },
    });
  });
};

const confirmNewEmailId = ({ otp, newPassword }) => {
  setUserPool();
  const cognitoUser = new CognitoUser({
    Username: localStorage.getItem("newEmail"),
    Pool: userPool,
  });

  return new Promise((success, error) => {
    cognitoUser.confirmRegistration(otp, true, {
      onSuccess: (result) => {
        success(result);
      },

      onFailure: (err) => {
        error(err);
      },

      // To set a new password immediately after confirming the email, use the following:
      // cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
      //   onSuccess: (result) => {
      //     resolve(result);
      //   },
      //   onFailure: (err) => {
      //     reject(err);
      //   },
      // });
    });
  });
};

const forgotPasswordReset = ({ username }) => {
  setUserPool();
  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  return new Promise((success, error) => {
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        success("OTP generated successfully");
      },
      onFailure: function (err) {
        error(err.message);
      },
    });
  });
};

const getCurrentUser = () => {
  setUserPool();
  return userPool.getCurrentUser();
};

const logout = () => {
  setUserPool();
  return new Promise((success) => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser !== null) {
      //cognitoUser.globalSignOut()
      cognitoUser.signOut();
    }
    success("loged out");
  });
};

const isAuthenticated = () => {
  setUserPool();

  return new Promise((success, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      reject("Could not retrieve current user");
      return;
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err);
        return;
      }

      if (session.isValid()) {
        success(session);
      } else {
        reject("Session is not valid");
      }
    });
  });
};

const refreshToken = () => {
  setUserPool();
  const cognitoUser = userPool.getCurrentUser();
  return new Promise((success, reject) => {
    if (!cognitoUser) {
      reject("Could not retrieve current user");
      return;
    }
    cognitoUser.getSession(function (err, data) {
      if (err) {
        reject(err);
      } else {
        const cognitoUserSession = data;

        var tokenDict = {
          idToken: cognitoUserSession.getIdToken().jwtToken,
          accessToken: cognitoUserSession.getAccessToken().jwtToken,
        };
        success(tokenDict);
      }
    });
  });
};

const refreshsession = (email) => {
  setUserPool();
  const cognitoUser = new CognitoUser({
    Username: localStorage.getItem("email"),
    Pool: userPool,
  });

  const refreshtoken = new CognitoRefreshToken({
    RefreshToken: localStorage.getItem("RefreshToken"),
  });

  return new Promise((success, error) => {
    cognitoUser.refreshSession(refreshtoken, (err, session) => {
      if (err) {
        localStorage.clear();
        // window.location.reload(false);
      } else {
        localStorage.setItem(
          "AccessToken",
          session.getAccessToken().getJwtToken()
        );
        // window.location.reload(false);
      }
    });
  });
};

const CognitoService = {
  registerUser,
  login,
  getCurrentUser,
  logout,
  isAuthenticated,
  setNewPassword,
  forgotPasswordReset,
  confirmNewForgotPassword,
  confirmNewEmailId,
  refreshToken,
  refreshsession,
};

export default CognitoService;
