import { makeActionCreator } from './index'

export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const LOGIN = 'LOGIN'
export const SIGNUP = 'SIGNUP'
export const LOGOUT = 'LOGOUT'
export const AUTH_FAILURE = 'AUTH_FAILURE'

export const login = makeActionCreator(LOGIN)
export const authSuccess = makeActionCreator(AUTH_SUCCESS)
export const logout = makeActionCreator(LOGOUT)
export const signUp = makeActionCreator(SIGNUP)
export const authFailure = makeActionCreator(AUTH_FAILURE)
