import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Box, Button, TextField } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const Captcha = forwardRef(({ onValidate, lable, helpText }, ref) => {
  const randomString = Math.random().toString(36).slice(2, 8);
  const [captcha, setCaptcha] = useState(randomString);
  const [text, setText] = useState("");
  // const [valid, setValid] = useState(false);

  useImperativeHandle(ref, () => ({
    getValue: () => text,
    validateCaptcha: () => {
      const isValid = text === captcha;
      // setValid(!isValid);
      onValidate(isValid);
      refreshString()
    }
  }));

  const refreshString = () => {
    setText("");
    setCaptcha(Math.random().toString(36).slice(2, 8));
  };

  return (
    <>
      <Box className="captchaCard MB20">
        <Box>
          <Box className="DF ">
            <Box className="captcha DF JCSB AIC">{captcha}</Box>
            <Button startIcon={<RefreshIcon />} onClick={refreshString} className="refreshBtnCaptcha"></Button>
          </Box>

          <TextField
            label={lable}
            value={text}
            className="accordionbggrey captchaInput"
            InputProps={{
                style: {
                  height: "50px",
                  color: "#636363",
                },
              }}
            onChange={(e) => setText(e.target.value)}
            // error={valid}
            // helperText={valid && helpText}
          />
        </Box>
      </Box>
    </>
  );
});

export default Captcha;