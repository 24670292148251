import {
  AUTH_SUCCESS,
  LOGIN,
  SIGNUP,
  LOGOUT,
  AUTH_FAILURE,
} from "../actions/auth";

export const AuthInitialState = {
  user: null,
  token: null,
  loading: false,
  authError: { error: null },
  isAdmin: false,
  isQA: false,
  isAnalyst: false,
};

const AuthReducer = (state = AuthInitialState, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        authError: { error: null },
        ...action.payload,
      };
    case AUTH_FAILURE:
      return {
        ...state,
        user: null,
        token: null,
        authError: action.payload,
      };
    case LOGIN:
      return {
        ...state,
        loading: true,
      };
    case SIGNUP:
      return {
        ...state,
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        loading: false,
        isAdmin: false,
        isQA: false,
        isAnalyst: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
