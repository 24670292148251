import { useEffect, useReducer } from "react";

export const useLocallyPersistedReducer = (reducer, defaultState, key) => {
  const persistedState =
    typeof window !== "undefined" && JSON.parse(localStorage.getItem(key));
  const [state, dispatch] = useReducer(reducer, defaultState, (stateArg) =>
    persistedState ? persistedState : stateArg
  );

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, dispatch];
};
