import { Box, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ThankYouEmail from "../../Assets/Icons/ThanYouEmail.svg";
import CadminTwitter from "../../Assets/Svgs/CadminTwitter.svg";
import CadminInstagram from "../../Assets/Svgs/CadminInstagram.svg";
import CadminLinkedin from "../../Assets/Svgs/CadminLinkedin.svg";
import { TwitterShareButton, LinkedinShareButton } from "react-share";
import { Helmet } from "react-helmet";

export default function ThankYou() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Helmet>
        {/* <!-- Event snippet for Israel Hebrew LP conversion page --> */}
        <script>
          {`gtag('event', 'conversion', {'send_to': 'AW-11227532745/S_CNCNibj8wZEMmb2ukp'});`}
        </script>
      </Helmet>
      <Box component={"div"} className="thankyouBG ">
        <Box className="container DF FFC JCC AIC thankyouGPTopBottom">
          <img
            className="thankyouemailicon"
            src={ThankYouEmail}
            alt="thank you email icon"
          />
          <Typography variant="h1" className="thankyouheading">
            Thank You!
          </Typography>
          <Typography variant="h1" className="thankyouheadingdescription">
            Your submission has been received.<br></br> We'll be in touch and
            contact you soon.
          </Typography>
          <Button
            className="thankyoubutton"
            onClick={() => {
              navigate("/");
            }}
          >
            Go to Home Page
          </Button>
          <Typography variant="h1" className="thankyousocialtext">
            Connect with us
          </Typography>
          <Box className="thankyousocialmediacontainer">
            <LinkedinShareButton
              url={window.location.href}
              media={window.location.href}
              quote={"Dummy text!"}
            >
              <img
                className="thankyousocialmediaicon"
                src={CadminLinkedin}
                alt="linkdin"
              />
            </LinkedinShareButton>
            <img
              className="thankyousocialmediaicon"
              src={CadminInstagram}
              alt="instagram"
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/lifeatindxx/?hl=en")
              }
            />
            <TwitterShareButton
              url={window.location.href}
              media={window.location.href}
              quote={"Dummy text!"}
            >
              <img
                className="thankyousocialmediaicon"
                src={CadminTwitter}
                alt="twitter"
              />
            </TwitterShareButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}
