import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
// import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import CloseIcon from "@mui/icons-material/Close";
import { postMethodApi } from "../../Utils/Methods";
import { POST_SIGN_UP_OTP } from "../../Apis/EndPoints";

export default function ConfirmID({
  toggle,
  setToggle,
  setLoginScreen,
  setSignUpScreen,
  setForgotScreen,
  otpScreen,
  setOtpScreen,
  otpConfirmScreen,
  setOtpConfirmScreen,
  onFinishForgotPassword,
  // onSetConfirmForgotPasswordFinish,
}) {
  const [reRender, setRerender] = useState(true);
  const [confirmOTPForm, setPasswordChange] = useState({
    otp: "",
    email: localStorage.getItem("emailForOTP"),
  });

  useEffect(() => {
    setPasswordChange((prevData) => ({
      ...prevData,
      email: localStorage.getItem("emailForOTP"),
    }));
        // eslint-disable-next-line
  }, [localStorage.getItem("emailForOTP")]);

  const handleChangeOTP = (newValue) => {
    setPasswordChange({
      ...confirmOTPForm,
      otp: newValue,
    });
  };

  function matchIsString(text) {
    return typeof text === "string" || text instanceof String;
  }

  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = matchIsString(text);
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  const createContactInfo = () => {
    if (confirmOTPForm.otp.trim() === "" || confirmOTPForm.otp.length < 6) {
      toast.error("Enter valid OTP");
      return;
    }

    postMethodApi(POST_SIGN_UP_OTP, confirmOTPForm)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Your Email is Verified");
          onFinishForgotPassword({
            emailid: localStorage.getItem("emailForOTP"),
          });
          localStorage.removeItem("emailForOTP");
          setTimeout(() => {
            toast.success("OTP has been sent.");
          }, 2000);
          setPasswordChange({
            otp: "",
            email: "",
          });
          setRerender(false);
          setTimeout(() => {
            setRerender(true);
          }, 0);
          setSignUpScreen(false);
          setOtpConfirmScreen(false);
          // Create your Password
          setOtpScreen(true);
        }
        if (res.status === 401) {
          toast.error("Enter valid OTP");
        }
        if (res.status === 400) {
          toast.error(res.data?.message);
        }
      })
      .catch((error) => {
        toast.error("Some error occur ");
        return;
      });
    // onSetConfirmForgotPasswordFinish(confirmOTPForm);
  };
  const handleComplete = (value) => {
    setPasswordChange({
      ...confirmOTPForm,
      otp: value,
    });
  };
  const backToSignUp = () => {
    localStorage.removeItem("emailForOTP");
    setSignUpScreen(true);
    setForgotScreen(false);
    setOtpScreen(false);
    setOtpConfirmScreen(false);
    setLoginScreen(false);
    setPasswordChange({
      otp: "",
      email: "",
    });
    setRerender(false);
    setTimeout(() => {
      setRerender(true);
    }, 0);
  };

  return (
    <Box
      className="loginContainer DF JCC AIC"
      display={toggle && otpConfirmScreen ? "" : "none"}
    >
      {confirmOTPForm?.email ? (
        <Box className="boxshadow4sides bordr10px otpForm P2 PB1">
          <Box>
            <Box className="DF JCSB">
              <Typography variant={"h1"} className="contactusformheading">
                {"Verify Your Email"}
              </Typography>
              <Box
                className="cursorPointer"
                onClick={() => {
                  localStorage.removeItem("emailForOTP");
                  setToggle(false);
                  setOtpConfirmScreen(false);
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
            <Box className="DF JCSB AIC">
              <Typography variant={"h6"} className="loginTextSubHeading">
                {"Enter OTP from your email address"} {confirmOTPForm?.email}
              </Typography>
            </Box>
            {reRender ? (
              <Box
                sx={{ mt: 3.25 }}
                className="DF JCSB FFC getintouchinputfield"
              >
                <MuiOtpInput
                  autoFocus
                  classes={"accordionbggrey LoginInputs"}
                  value={confirmOTPForm.otp}
                  onChange={handleChangeOTP}
                  className="MB1"
                  length={6}
                  itemType="tel"
                  inputMode="numeric"
                  validateChar={validateChar}
                  onComplete={handleComplete}
                  TextFieldsProps={{
                    disabled: false,
                    placeholder: String("*"),
                  }}
                />
              </Box>
            ) : (
              ""
            )}
            <Button
              className="sendmsgBtnLogin mobMT20 mobMAuto linehtN"
              onClick={createContactInfo}
            >
              {"Confirm"}
            </Button>
            <Box className="DF JCSB PT10">
              <Box className="cursorPointer hoverUnderline" onClick={backToSignUp}>
                Back to SignUp
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}
