import { Button, CssBaseline, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { getMethodApi, postMethodApi } from "../../Utils/Methods";
import { GET_CONFIG, GET_TABS, POST_CONTACT_US } from "../../Apis/EndPoints";
import { useState } from "react";
import { useEffect } from "react";
import useStyles from "../../Assets/Styles/Common/Common";
import Logo from "./../../Assets/Svgs/indxxLogoWhite.svg";
import Map from "./../../Assets/Icons/map-location.svg";
import Call from "./../../Assets/Icons/call.svg";
import Email from "./../../Assets/Icons/mail.svg";
import ArrowRight from "./../../Assets/Icons/arrowRightSmall.svg";
import { ToastContainer, toast } from "react-toastify";
import LanguageIcon from '@mui/icons-material/Language';

// import { UseAuthContext } from "../../context/auth-context";
// import { email } from "react-share";

function Copyright() {
  return (
    <Typography variant="body2">
      <NavLink
        to="https://www.indxx.com/"
        target="_blank"
        className="linkFooter"
      >
        {"© All Copyright, Indxx 2023 All Rights Reserved."}
      </NavLink>
    </Typography>
  );
}

export default function Footer() {
  const navigate = useNavigate();
  const {
    menuButtonFooter,
    footerLowerContainer,
    footerUpperContainer,
    footerUpperContainerKorean,
    footerUnderline,
    footerHeading,
    text_18_footer,
    footerTextContactUs,
    footerHeadingBox,
  } = useStyles();
  const [tabsDataFooter, setTabsDataFooter] = useState([]);
  const [address, setaddress] = useState([]);
  const [contactInfo, setContactInfo] = useState({
    Email: "",
    ip_address: "",
    page_name: "Footer",
  });
  const [koreanHidingFooter, setAdvertisingFooter] = useState(false);
  // const [isAnnouncmentEnable, setIsAnnouncmentEnable] = useState(null);
  const location = useLocation();
  // const { token } = UseAuthContext();

  useEffect(() => {
    if (
      location?.pathname?.includes("%ED%95%9C%EA%B5%AD%EC%96%B4") ||
      location?.pathname?.includes("%D7%A2%D6%B4%D7%91%D7%A8%D6%B4%D7%99%D7%AA")
    ) {
      setAdvertisingFooter(true);
    } else {
      setAdvertisingFooter(false);
    }
  }, [location?.pathname]);

  useEffect(() => {
    getMethodApi(GET_TABS).then((response) => {
      if (response.status === 200) {
        setTabsDataFooter(response.data.F);
      }
    });
    getMethodApi(GET_CONFIG).then((response) => {
      if (response.status === 200) {
        setaddress(response.data["Contact Us"]);
        // setIsAnnouncmentEnable(Number(response.data["Announcment_page_enable"][0].value))
      }
    });
  }, []);

  // useEffect(() => {
  //   if (token) {
  //     setIsAnnouncmentEnable(1);
  //   } else {
  //     setIsAnnouncmentEnable(0);
  //   }
  //   // eslint-disable-next-line
  // }, [token]);

  const getMenuButtonsAll = () => {
    return tabsDataFooter?.map(({ Name, Link }, ind) => {
      // if (isAnnouncmentEnable === 0 && Name === 'Announcements') {
      //   return ''
      // }
      return (
        <Box
          // {...{
          key={ind}
          // to: Name === "Home" ? "/" : Name.toLowerCase().split(" ").join(""),
          className={`${menuButtonFooter} links noWrap mobMB1 menuButtonFooter`}
          onClick={() =>
            navigate(
              Name === "Home" ? "/" : Name.toLowerCase().split(" ").join("-")
            )
          }
          // }}
        >
          {Name}
        </Box>
      );
    });
  };

  const handleChange = (event) => {
    setContactInfo({
      ...contactInfo,
      [event.target.name]: event.target.value,
    });
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.trim().length > 0 && emailRegex.test(email) && !email.includes(",") && !email.includes(" ");
  }

  const createContactInfo = () => {
    contactInfo.ip_address = localStorage.getItem("IPAddress");

    if (!isValidEmail(contactInfo.Email)) {
      toast.error("Enter Correct Email Address ");
      return;
    }

    postMethodApi(POST_CONTACT_US, contactInfo)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Contact Information is sent ");
          setContactInfo({
            Email: "",
            page_name: "",
          });
          navigate("/thank-you");
        }
        if (res.status === 400) {
          toast.error(res.data?.message);
        }
      })
      .catch((error) => {
        toast.error("Some error occur ");
        return;
      });
  };

  return (
    <>
      <CssBaseline />
      {!koreanHidingFooter ? (
        <Box>
          <Box className={`${footerUpperContainer} MT60`}>
            <Box className="DF JCSB FWW FGap2R">
              <Box className="DF FFC">
                <Box className={`${footerHeadingBox} MB40`}>
                  <img src={Logo} alt="white logo indxx" />
                </Box>
                <Box>
                  <Typography variant="p" className={text_18_footer}>
                    Founded in 2005 and with offices in the US and India, Indxx
                    is a leading global index provider. Indxx offers end-to-end
                    indexing services including index licensing, development,
                    calculation, and administration as well as{" "}
                    <Link
                      to={"https://www.indxx.com/indxx-data-&-technology"}
                      className="colorWhite"
                    >
                      {" "}
                      data and technology products
                    </Link>{" "}
                    (CAdmin & ITICS).
                  </Typography>
                </Box>
              </Box>
              <Box className="DF FFC">
                <Box className={`DF ${footerHeadingBox} FFC JCSB MB40`}>
                  <Typography variant="" className={footerHeading}>
                    {"Company"}
                  </Typography>
                  <Box className={footerUnderline}></Box>
                </Box>
                <Box className="DF FFC W10R"> {getMenuButtonsAll()} </Box>
              </Box>
              <Box className="DF FFC">
                <Box className={`DF ${footerHeadingBox} FFC JCSB MB40`}>
                  <Typography variant="" className={footerHeading}>
                    {"Contact Us"}
                  </Typography>
                  <Box className={footerUnderline}></Box>
                </Box>
                <Box className="DF FFC JCSB IStretchF">
                  <Box className="DF FFC ">
                    {address
                      ?.filter((ele, ind) => {
                        return ele.value !== "Human Resources";
                      })
                      ?.map((ele, indx) => {
                        let seprate = ele.description.split(";");
                        let img;
                        if (ele.value === "PHONE") {
                          img = Call;
                        } else if (ele.value === "EMAIL") {
                          img = Email;
                        } else {
                          img = Map;
                        }
                        return (
                          <Box className={`DF FFR JCFS AIFS`} key={indx}>
                            <Box className="W3R">
                              <img src={img} alt="map" className="MT5" />
                            </Box>
                            <Box className="MB20">
                              {seprate?.map((ele2, ind) => {
                                return (
                                  <Typography
                                    key={ind}
                                    variant="p"
                                    className={`${footerTextContactUs}`}
                                  >
                                    {ele.value === "PHONE" ? (
                                      <a
                                        href={`tel:${ele2
                                          .split("INDXX")
                                          .join("")}`}
                                        className={`${footerTextContactUs}`}
                                      >
                                        {ele2}
                                      </a>
                                    ) : (
                                      ele2
                                    )}
                                  </Typography>
                                );
                              })}
                            </Box>
                          </Box>
                        );
                      })}{" "}
                  </Box>
                  <Box
                    component={"div"}
                    className="bGColorWhite subscribeBtnBoxFooter bordRadius60 DF JCSB AIC"
                  >
                    <input
                      name="Email"
                      type="email"
                      className="subscribeInput bordRadius60 PL30 W60"
                      placeholder="Enter your email"
                      value={contactInfo.Email}
                      onChange={handleChange}
                    />
                    <Button
                      className="bGColorBlue subscribeBtnFooter bordRadius60 colorWhite PL20 PR20 W40"
                      onClick={createContactInfo}
                    >
                      <img src={ArrowRight} alt="" className="MR10" />{" "}
                      {"Subscribe"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={`DF JCC AIC ${footerLowerContainer}`}>
            {Copyright()}
          </Box>
        </Box>
      ) : (
        <Box>
          <Box className={`${footerUpperContainerKorean} MT60`}>
            <Box className="DF JCSB FWW FGap2R">
              <Box className="DF FFC IStretchF">
                <Box className="DF JCSB verySmallMobFFC">
                  <Box className={`DF FFR JCFS AIFS`}>
                    <Box className="W3R">
                      <LanguageIcon className="svgIconWeb" />
                    </Box>
                    <Box className="MB20">
                      <Typography
                        variant="p"
                        className={`${footerTextContactUs}`}
                      >
                        <a
                          href={`https://www.indxx.com/`}
                          target="_blank"
                          rel="noreferrer"
                          className={`${footerTextContactUs}`}
                        >
                          www.indxx.com
                        </a>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={`DF FFR JCFS AIFS`}>
                    <Box className="W3R">
                      <img src={Email} alt="map" className="MT5" />
                    </Box>
                    <Box className="MB20">
                      <Typography
                        variant="p"
                        className={`${footerTextContactUs}`}
                      >
                        <a
                          href={`mailto:info@indxx.com`}
                          target="_blank"
                          rel="noreferrer"
                          className={`${footerTextContactUs}`}
                        >
                          info@indxx.com
                        </a>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={`DF FFR JCFS AIFS`}>
                    <Box className="W3R">
                      <img src={Call} alt="map" className="MT5" />
                    </Box>
                    <Box className="MB20">
                      <Typography
                        variant="p"
                        className={`${footerTextContactUs}`}
                      >
                        <a
                          href={`tel:+1(844)55-(46399)`}
                          target="_blank"
                          rel="noreferrer"
                          className={`${footerTextContactUs}`}
                        >
                          +1(844)55-INDXX(46399)
                        </a>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
