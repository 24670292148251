import { Box, Typography, Checkbox } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { TextField, Button } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

import { getMethodApi, postMethodApi } from "../../Utils/Methods";
import {
  GET_CONFIG,
  GET_OFFICE_LOCATION,
  POST_CONTACT_US,
} from "../../Apis/EndPoints";
import Loader from "../../Components/Loader/Loader";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Captcha from "../../Components/Captcha/Captcha";

// const contactUs = [{ icon: phoneimg }, { icon: emailimg }, { icon: emailimg }];

export default function ContactUs() {
  const navigate = useNavigate();
  const [location, setLocation] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loader1, setLoader1] = useState(true);
  const [seoData, setSeoData] = useState("");
  const [loader2, setLoader2] = useState(true);
  const [setTimeOutLoader, setSetTimeOutLoader] = useState(true);
  const [contactInfo, setContactInfo] = useState({
    name: "",
    Company: "",
    Email: "",
    Message: "",
    Phone: "",
    ip_address: "",
    checkboxCookie: false,
    page_name: window.location.href.split("/").slice(-1)[0],
  });
  const locationLink = useLocation();
  const getInTouch = useRef();
  const captchaRef = useRef();
  const isClicked = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      let queryParam = locationLink?.pathname?.split("contactus/")[1];
      if (queryParam === "getintouch") {
        getInTouch.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }, 500);
  }, [locationLink?.pathname]);

  useEffect(() => {
    getMethodApi(GET_CONFIG).then((response) => {
      if (response.status === 200) {
        setSeoData(response.data);
        setLoader(false);
        setLoader2(false);
      }
    });
    getMethodApi(GET_OFFICE_LOCATION).then((response) => {
      if (response.status === 200) {
        setLocation(response.data);
        setLoader1(false);
      }
    });

    setTimeout(() => {
      setSetTimeOutLoader(false);
    }, 2000);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (event) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setContactInfo({
      ...contactInfo,
      [event.target.name]: sanitizedValue,
    });
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (
      email.trim().length > 0 &&
      emailRegex.test(email) &&
      !email.includes(",") &&
      !email.includes(" ")
    );
  }

  const createContactInfo = (isValid) => {
    contactInfo.ip_address = localStorage.getItem("IPAddress");

    if (!isValidEmail(contactInfo.Email)) {
      toast.error("Enter Correct Email Address ");
      return;
    }

    // if (contactInfo.name.trim() === "") {
    //   toast.error("Name is required ");
    //   return;
    // }

    // if (contactInfo.Company.trim() === "") {
    //   toast.error("Company name is required ");
    //   return;
    // }

    // if (contactInfo.Email.trim() === "") {
    //   toast.error("Email is required ");
    //   return;
    // }

    // if (
    //   !contactInfo.Email.split("").includes("@") ||
    //   !contactInfo.Email.split("").includes(".") ||
    //   contactInfo.Email.includes(" ")
    // ) {
    //   toast.error("Enter proper email");
    //   return;
    // }

    // if (!captchaRef.current.getValue()) {
    //   toast.error("Captcha is required");
    //   return;
    // }

    if (!isValid) {
      toast.error("Invalid Captcha");
      return;
    }

    if (!contactInfo.checkboxCookie) {
      toast.error("Please accept Terms of Usage");
      return;
    }

    if (!isClicked.current) {
      setLoader(true);
      isClicked.current = true;
      postMethodApi(POST_CONTACT_US, contactInfo)
        .then((res) => {
          if (res.status === 201) {
            setContactInfo({
              name: "",
              Company: "",
              Email: "",
              Phone: "",
              checkboxCookie: false,
            });
            setLoader(false);
            toast.success("Contact Information is sent");
            navigate("/thank-you");
          }
          if (res.status === 400) {
            toast.error(res.data?.message);
            setLoader(false);
          }
        })
        .catch((error) => {
          toast.error("Some error occurred");
          setLoader(false);
          return;
        })
        .finally(() => {
          setLoader(false);
          isClicked.current = false; // Reset the flag after the API call completes
        });
    }
  };

  const handleCaptchaValidation = (isValid) => {
    // if (isValid) {
    //   createContactInfo();
    // } else {
    //   toast.error("Invalid Captcha");
    // }
    createContactInfo(isValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    captchaRef.current.validateCaptcha();
  };

  return (
    <>
      <Helmet>
        <title>
          {seoData?.Contact_us_Title
            ? seoData.Contact_us_Title[0].value
            : "Title"}
        </title>
        <meta
          name="description"
          content={
            seoData?.Contact_us_Description
              ? seoData.Contact_us_Description[0].value
              : "description"
          }
        />
        <meta
          name="keywords"
          content={
            seoData?.Contact_us_Keywords
              ? seoData.Contact_us_Keywords[0].value
              : "keys"
          }
        />
      </Helmet>
      <Box className="contactusbannerimg DF JCC AIC">
        <Typography variant={"h1"} className="contactusbannerimgpara">
          {"Contact Us"}
        </Typography>
      </Box>

      <Box>
        <Box component={"div"} className="container">
          <Box
            component={"div"}
            className="getintouchContainer DF JCC Fgap27 mobAIC MT4REM verySmallMobMT30"
          >
            <Box className="boxshadow4sides bordr10px getintouchform">
              <Box sx={{ p: 3.26 }}>
                <Typography variant={"h1"} className="contactusformheading">
                  {"Get in Touch"}
                </Typography>

                <Box
                  sx={{ mt: 3.25 }}
                  className="DF JCSB FWW getintouchinputfield"
                >
                  <TextField
                    name="name"
                    label="Name"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                    }}
                    onChange={handleChange}
                    value={contactInfo.name}
                  />

                  <TextField
                    name="Company"
                    label="Company"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                    }}
                    onChange={handleChange}
                    value={contactInfo.Company}
                  />

                  <TextField
                    name="Email"
                    label="Email*"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                    }}
                    onChange={handleChange}
                    value={contactInfo.Email}
                  />

                  <TextField
                    ref={getInTouch}
                    name="Phone"
                    label="Phone"
                    type="number"
                    className="accordionbggrey textfieldstyle"
                    sx={{ color: "#636363" }}
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                    }}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 15);
                    }}
                    onChange={handleChange}
                    value={contactInfo.Phone}
                  />
                </Box>

                <TextField
                  name="Message"
                  label="Message"
                  className="accordionbggrey textfieldstylefull"
                  id="outlined-multiline-static linehtN"
                  multiline
                  rows={4}
                  size="small"
                  sx={{ height: 100, color: "#636363" }}
                  InputProps={{
                    style: {
                      height: "100px",
                      color: "#636363",
                    },
                  }}
                  onChange={handleChange}
                  value={contactInfo.Message}
                />

                {/* <Box className="reCaptcha">
                  <ReCaptchaV2
                    // sitekey={"6LfoBRUgAAAAAL6e1-thG56JCxpCRfDs_r5zoDry"}
                    sitekey={"6Ld5c_UoAAAAAHqS4gazOrVdYHcxJ6t5K-9MfKfY"}
                    // onChange={handleToken}
                    // onExpire={handleExpire}
                    className="MB20"
                    ref={captchaRef}
                  />
                </Box> */}
                <Captcha
                  ref={captchaRef}
                  onValidate={handleCaptchaValidation}
                  lable={"Enter Captcha"}
                  // helpText={"Invalid Captcha"}
                />

                <Box component={"div"} className="DF AIC MB20 verySmallMobMB10">
                  <Checkbox
                    style={{ padding: "2px 1rem 0px 0rem" }}
                    className=""
                    name="checkboxCookie"
                    checked={contactInfo.checkboxCookie}
                    onChange={handleChange}
                  />
                  <Typography className="Contactustermsofusage linehtN colorBlack">
                    {"I accept Indxx's terms of usage, acknowledge the "}
                    <Link to={"/data-privacy"} className="colorBlack">
                      {"Data Privacy"}
                    </Link>
                    {" Policy, and authorize to contact."}
                  </Typography>
                </Box>

                <Button
                  className="sendmsgBtn mobMT20 mobMAuto linehtN"
                  onClick={handleSubmit}
                >
                  {"Submit"}
                </Button>
                <Typography className="MT10">*Mandatory</Typography>
              </Box>
            </Box>
            {/* <Box className="callandemailbox">
              {aboutUs
                ?.filter((ele) => {
                  return ele.value !== "ADDRESS";
                })
                .map((ele, ind) => {
                  let name = ele.description;
                  if (ele.value === "EMAIL") {
                    name = ele.description.split(";")[0];
                  }
                  return (
                    <Box
                      className="DF iconsboxstyle MT1 boxshadow4sides bordr5px"
                      key={ind}
                    >
                      <Box className="bordr5px DF JCC AIC iconimgstyle">
                        <img src={contactUs[ind]?.icon} alt="phoneimg" />
                      </Box>
                      <Box className="paddingtop125">
                        <Typography
                          variant="body1"
                          component={"p"}
                          className="iconssubhead"
                        >
                          {ele.value === "PHONE"
                            ? "Call Us"
                            : ele.value === "EMAIL"
                            ? "Email"
                            : ele.value}
                        </Typography>
                        <Typography
                          variant="body1"
                          component={"p"}
                          className="iconscontent"
                        >
                          {name}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box> */}
          </Box>

          {location?.length > 0 ? (
            <Typography variant={"h3"} className="contactuslocationheading">
              {"Office Locations"}
            </Typography>
          ) : (
            ""
          )}

          <Box className="DF FWW FGap2R mobJCC mobFGap0">
            {location.map((continents, i) => (
              <Box className="MT60 mobMT30" key={i}>
                <Box className="locationBtn mobMT20 mobMAuto DF AIC JCC PL10 PR10">
                  {Object.keys(continents)}
                </Box>
                <Box className={`locationbox DF FWW FGap2R JCFS mobJCC`}>
                  {Object.values(continents).map((item, i) => {
                    return item.map((city, ind) => (
                      <Box className="DF FFC" key={ind}>
                        <img
                          className="locationimg"
                          src={city.Image}
                          alt="locationimg"
                        />
                        <Typography variant={"p"} className="locationimgtext">
                          {city.city}
                        </Typography>
                      </Box>
                    ));
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {setTimeOutLoader || loader || loader1 || loader2 ? <Loader /> : ""}
    </>
  );
}
