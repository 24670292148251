import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box } from "@mui/system";
import "react-toastify/dist/ReactToastify.css";

import "./Assets/Styles/Common/Common.scss";
import "./Assets/Styles/Home/Home.scss";
import "./Assets/Styles/Carousel/Carousel.scss";
import "./Assets/Styles/Offering/Offering.scss";
import "./Assets/Styles/ITICS/ITICS.scss";
import "./Assets/Styles/Cadmin/Cadmin.scss";
import "./Assets/Styles/News&Research/NewsResearch.scss";
import "./Assets/Styles/Announcement/Announcement.scss";
import "./Assets/Styles/DataPrivacy/DataPrivacy.scss";
import "./Assets/Styles/CookiePolicy/CookiePolicy.scss";
import "./Assets/Styles/Benchmarkindices/Benchmarkindices.scss";
import "./Assets/Styles/AboutUs/AboutUs.scss";
import "./Assets/Styles/Contactus/Contactus.scss";
import "./Assets/Styles/Indices/Indices.scss";
import "./Assets/Styles/Career/Career.scss";
import "./Assets/Styles/PressRelease/PressRelease.scss";
import "./Assets/Styles/ThankYou/ThankYou.scss";
import "./Assets/Styles/NewsAndResearchIndsider/NewsAndResearchIndsider.scss";
import "./Assets/Styles/Cookie/Cookie.scss";
import "./Assets/Styles/CareerJobDescription/CareerJobDescription.scss";
import "./Assets/Styles/Toastify/Toastify.scss";
import "./Assets/Styles/Graph.scss";
import "./Assets/Styles/404Errorpage/Errorpage.scss";

// components
import Header from "./Components/Layouts/Header";
import Footer from "./Components/Layouts/Footer";
import ScrollButton from "./Components/ScrollButton/ScrollButton";

// pages
import Home from "./Pages/Home/Home";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Indices from "./Pages/Indices/Indices";
import IndxxSerivice from "./Pages/BusinessOffering/IndxxService/IndxxSerivice";
import Career from "./Pages/Career/Career";
import ContactUs from "./Pages/ContactUs/ContactUs";
// import Page404 from "./Pages/Page404/Page404";
import IndxxIndices from "./Pages/BusinessOffering/IndxxIndices/IndxxIndices";
import NewsResearch from "./Pages/News&Research/NewsResearch";
import Announcement from "./Pages/Announcement/Announcement";
import TypesOfIndices from "./Pages/TypesOfIndices/TypesOfIndices";
import IndxxDataAndTechnology from "./Pages/BusinessOffering/IndxxDataAndTechnology/IndxxDataAndTechnology";
import DataPrivacy from "./Pages/DataPrivacy/DataPrivacy";
import CookiePolicy from "./Pages/CookiePolicy/CookiePolicy";
import ITICS from "./Pages/ITICS/ITICS";
import PressRelease from "./Pages/PressRelease/PressRelease";
import ThankYou from "./Pages/ThankYou/ThankYou";
import NewsAndResearchInsider from "./Pages/NewsAndResearchIndsider/NewsAndResearchIndsider";

// import Graph from "./Pages/Graph";

import Cadmin from "./Pages/Cadmin/Cadmin";
import CustomIndices from "./Pages/BusinessOffering/CustomIndices/CustomIndices";
import ClientIndices from "./Pages/BusinessOffering/ClientIndices/ClientIndices";
import EquityBasketCalculation from "./Pages/BusinessOffering/EquityBasketCalculation/EquityBasketCalculation";
import CareerJobDescription from "./Pages/CareerJobDescription/CareerJobDescription";
import IndivisualIndexName from "./Pages/IndivisualIndexName/IndivisualIndexName";
// import GraphPdf from "./Components/GraphPdf/GraphPdf";
import Cookie from "./Components/Layouts/Cookie";
import Search from "./Pages/Search/Search";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import ContactUsLandingKorean from "./Pages/ContactUsLandingKorean/ContactUsLandingKorean";
import ContactUsLandingHerbrew from "./Pages/ContactUsLandingHebrew/ContactUsLandingHebrew";


function App() {

  useEffect(()=>{
    let a = window.location.href.split("/").slice(-1)[0];
    let b = window.location.href.slice(-4);
    let c = window.location.href.slice(-9);

    if(b === 'esg/'){
      window.history.pushState(null, '', '/indices/esg-indices');
      window.location.reload(true);
    }

    if(c === 'strategy/'){
      window.history.pushState(null, '', '/indices/strategy-indices');
      window.location.reload(true);
    }

    a = decodeURIComponent(a);
    if(a === 'indxxindices'){
      window.history.pushState(null, '', '/indxx-indices');
      window.location.reload(true);
    }
    else if(a === 'customindices'){
      window.history.pushState(null, '', '/custom-indices');
      window.location.reload(true);
    }
    else if(a === 'clientindices'){
      window.history.pushState(null, '', '/client-indices');
      window.location.reload(true);
    }
    else if(a === 'Equity-Basket-Calculation'){
      window.history.pushState(null, '', '/equity-basket-calculation');
      window.location.reload(true);
    }
    else if(a === 'benchmark'){
      window.history.pushState(null, '', '/indices/benchmark-indices');
      window.location.reload(true);
    }
    else if(a === 'benchmark'){
      window.history.pushState(null, '', '/indices/benchmark-indices');
      window.location.reload(true);
    }
    else if(a === 'digital_asset'){
      window.history.pushState(null, '', '/indices/digital-asset-indices');
      window.location.reload(true);
    }
    else if(a === 'esg'){
      window.history.pushState(null, '', '/indices/esg-indices');
      window.location.reload(true);
    }
    else if(a === 'income'){
      window.history.pushState(null, '', '/indices/income-indices');
      window.location.reload(true);
    }
    else if(a === 'strategy'){
      window.history.pushState(null, '', '/indices/strategy-indices');
      window.location.reload(true);
    }
    else if(a === 'thematic'){
      window.history.pushState(null, '', '/indices/thematic-indices');
      window.location.reload(true);
    }
    else if(a === 'other'){
      window.history.pushState(null, '', '/indices/other-indices');
      window.location.reload(true);
    }
    else if(a === 'technology'){
      window.history.pushState(null, '', '/indxx-data-&-technology');
      window.location.reload(true);
    }
    else if(a === 'indexservices'){
      window.history.pushState(null, '', '/');
      window.location.reload(true);
    }
  },[])

  return (
    <>
      <Router>
      <Helmet>
        <title>Indxx Website</title>
        <meta
          name="description"
          content="Get stats about every music from every movie"
        />
        <meta
          name="keywords"
          content="Music, Audio, Lyrics"
        />
      </Helmet>
        <Header />

        <Box className="centerContainer">
          <Routes>
            {/* Tab Pages */}
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/aboutus/:samePage" element={<AboutUs />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/about-us/:samePage" element={<AboutUs />} />
            <Route path="/indices" element={<Indices />} />
            <Route path="/careers" element={<Career />} />
            <Route path="/contact-us/careers" element={<Career />} />
            <Route path="/contact-us/한국어" element={<ContactUsLandingKorean />} />
            <Route path="/contact-us/עִברִית" element={<ContactUsLandingHerbrew />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/contact-us/:samePage" element={<ContactUs />} />
            <Route path="/contactus/careers" element={<Career />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/contactus/:samePage" element={<ContactUs />} />
            <Route path="/news-&-research" element={<NewsResearch />} />
            <Route path="/news-&-research/:samePage" element={<NewsResearch />} />
            <Route path="/announcements" element={<Announcement />} />
            <Route path="/announcements/:samePage" element={<Announcement />} />
            <Route path="/assets/media/document/IOSCO_Principles_-_Statement_of_Adherence1.pdf" element={<Announcement />} />

            {/* https://www.indxx.com/assets/media/document/IOSCO_Principles_-_Statement_of_Adherence1.pdf */}

            {/* 404 Page */}
            {/* <Route path="*" element={<Page404 />} />  */}

            {/* 404 page with search */}
            <Route path="*" element={<Search />} />

            {/* Indxx Indices */}
            <Route path="/indxx-indices" element={<IndxxIndices />} />
            {/* <Route path="/Indxxindices" element={<IndxxIndices />} /> */}
            <Route path="/custom-indices" element={<CustomIndices />} />
            {/* <Route path="/Customindices" element={<CustomIndices />} /> */}
            <Route path="/client-indices" element={<ClientIndices />} />
            {/* <Route path="/Clientindices" element={<ClientIndices />} /> */}
            <Route
              path="/equity-basket-calculation"
              element={<EquityBasketCalculation />}
            />
            {/* <Route
              path="/Equity-Basket-Calculation"
              element={<EquityBasketCalculation />}
            /> */}
            <Route path="/index-services" element={<IndxxSerivice />} />

            <Route
              path="/indxx-data-&-technology"
              element={<IndxxDataAndTechnology />}
            />

            {/* <Route
              path="/technology"
              element={<IndxxDataAndTechnology />}
            /> */}

            {/* Indices */}
            <Route path="/indices/:indices" element={<TypesOfIndices />} />
            <Route path="/indices/:indices/*" element={<TypesOfIndices />} />
            {/* <Route path="/indices/benchmark" element={<TypesOfIndices />} /> */}
            {/* <Route path="/indices/digital_asset" element={<TypesOfIndices />} /> */}
            {/* <Route path="/indices/esg/" element={<TypesOfIndices />} /> */}
            {/* <Route path="/indices/income" element={<TypesOfIndices />} /> */}
            {/* <Route path="/indices/strategy/" element={<TypesOfIndices />} /> */}
            {/* <Route path="/indices/thematic" element={<TypesOfIndices />} /> */}
            {/* <Route path="/indices/other" element={<TypesOfIndices />} /> */}

            {/* Individual Indices */}
            <Route
              path="/indices/:indices/:indivisualindex"
              element={<IndivisualIndexName />}
            />

            {/* Other Pages */}
            <Route path="/data-privacy" element={<DataPrivacy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/itics" element={<ITICS />} />
            <Route path="/cadmin" element={<Cadmin />} />

            {/* Indxx Services Page*/}

            {/* Press Release Page*/}
            <Route path="/news-&-research/press-releases/:id" element={<PressRelease />} />
            <Route
              path="/news-&-research/press-releases/:id"
              element={<PressRelease />}
            />
            {/* Thank You*/}
            <Route path="/thank-you" element={<ThankYou />} />

            {/* News And Research Insider*/}
            <Route
              path="/news-&-research/news-and-research-insider/:id"
              element={<NewsAndResearchInsider />}
            />
            {/* <Route
              path="/blog/:id"
              element={<NewsAndResearchInsider />}
            /> */}

            {/* News And Research Insider*/}
            <Route
              path="/careers/career-job-description/:id"
              element={<CareerJobDescription />}
            />
            <Route
              path="/contact-us/careers/career-job-description/:id"
              element={<CareerJobDescription />}
            />

            {/* search page */}
            <Route path="/search/:searchQuery" element={<Search />} />
          </Routes>
        </Box>
        <ScrollButton />

        <Footer />
        <Cookie />
      </Router>
    </>
  );
}

export default App;
