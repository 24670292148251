import {
  Box,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { TextField, Button } from "@mui/material";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { MuiOtpInput } from "mui-one-time-password-input";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import Loader from "../Loader/Loader";

export default function OTP({
  toggle,
  setToggle,
  setLoginScreen,
  setSignUpScreen,
  setForgotScreen,
  otpScreen,
  setOtpScreen,
  onSetConfirmForgotPasswordFinish,
  loader,
  setLoader,
}) {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [reRender, setRerender] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [passwordChange, setPasswordChange] = useState({
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });
  const userEmail = localStorage.getItem("forgotEmail");
  const passwordInfo = `Password should contain minimun 8 characters!
                        Password should have atleast 1 uppercase letter
                        Password should have atleast 1 special Character
                        Password should have atleast 1 number`;

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (event) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setPasswordChange({
      ...passwordChange,
      [event.target.name]: sanitizedValue,
    });
  };

  const handleChangeOTP = (newValue) => {
    setPasswordChange({
      ...passwordChange,
      otp: newValue,
    });
  };

  function matchIsString(text) {
    return typeof text === "string" || text instanceof String;
  }

  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = matchIsString(text);
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value, index) => {
    return matchIsNumeric(value);
  };

  const toolTipTimer = () => {
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 4000);
  };

  const createContactInfo = () => {
    /* eslint-disable */
    const hasNumber = /\d/;
    const hasUpperCase = /[A-Z]/;
    const hasSpecialCase = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    /* eslint-enable */

    if (passwordChange.otp.trim() === "" || passwordChange.otp.length < 6) {
      toast.error("Enter valid OTP");
      return;
    }

    if (passwordChange.newPassword.trim() === "") {
      toolTipTimer();
      return;
    }
    if (passwordChange.newPassword.length < 8) {
      toolTipTimer();
      return;
    }
    if (!hasNumber.test(passwordChange.newPassword)) {
      toolTipTimer();
      return;
    }
    if (!hasSpecialCase.test(passwordChange.newPassword)) {
      toolTipTimer();
      return;
    }
    if (!hasUpperCase.test(passwordChange.newPassword)) {
      toolTipTimer();
      return;
    }

    if (passwordChange.confirmPassword !== passwordChange.newPassword) {
      toast.error("Confirm password is not same");
      return;
    }
    setLoader(true);
    setPasswordChange({
      otp: "",
      newPassword: "",
      confirmPassword: "",
    });
    setRerender(false);
    setTimeout(() => {
      setRerender(true);
    }, 0);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    onSetConfirmForgotPasswordFinish(passwordChange);
    // setToggle(false);
  };
  const handleComplete = (value) => {
    setPasswordChange({
      ...passwordChange,
      otp: value,
    });
  };
  const backToLogin = () => {
    setSignUpScreen(false);
    setForgotScreen(false);
    setOtpScreen(false);
    setLoginScreen(true);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setPasswordChange({
      otp: "",
      newPassword: "",
      confirmPassword: "",
    });
    setRerender(false);
    setTimeout(() => {
      setRerender(true);
    }, 0);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Box
          className="loginContainer DF JCC AIC"
          display={toggle && otpScreen ? "" : "none"}
        >
          <Box className="boxshadow4sides bordr10px otpForm P2 PB1">
            <Box>
              <Box className="DF JCSB">
                <Typography variant={"h1"} className="contactusformheading">
                  {"Set New Password"}
                </Typography>
                <Box
                  className="cursorPointer"
                  onClick={() => {
                    setShowNewPassword(false);
                    setShowConfirmPassword(false);
                    setToggle(false);
                    setOtpScreen(false);
                  }}
                >
                  <CloseIcon />
                </Box>
              </Box>
              <Box className="DF JCSB AIC">
                <Typography variant={"h6"} className="loginTextSubHeading">
                  {"Enter OTP from your email address"} {userEmail}
                </Typography>

                <Tooltip
                  title={passwordInfo}
                  open={showTooltip}
                  placement="right"
                  className="cursorPointer"
                  onClick={() => {
                    setShowTooltip(!showTooltip);
                    setTimeout(() => setShowTooltip(false), 4000);
                  }}
                >
                  <InfoIcon sx={{ m: 1, color: "#898887" }} />
                </Tooltip>
              </Box>
              {reRender ? (
                <Box
                  sx={{ mt: 3.25 }}
                  className="DF JCSB FFC getintouchinputfield"
                >
                  <MuiOtpInput
                    autoFocus
                    classes={"accordionbggrey LoginInputs"}
                    value={passwordChange.otp}
                    onChange={handleChangeOTP}
                    className="MB1"
                    length={6}
                    itemType="tel"
                    inputMode="numeric"
                    validateChar={validateChar}
                    onComplete={handleComplete}
                    TextFieldsProps={{
                      disabled: false,
                      placeholder: String("*"),
                    }}
                  />

                  <TextField
                    name="newPassword"
                    label="New Password"
                    type={showNewPassword ? "text" : "password"}
                    className="accordionbggrey LoginInputs"
                    sx={{ color: "#636363" }}
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    value={passwordChange.newPassword}
                  />

                  <TextField
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showConfirmPassword ? "text" : "password"}
                    className="accordionbggrey LoginInputs"
                    sx={{ color: "#636363" }}
                    size="small"
                    InputProps={{
                      style: {
                        height: "50px",
                        color: "#636363",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChange}
                    value={passwordChange.confirmPassword}
                  />
                </Box>
              ) : (
                ""
              )}
              <Button
                className="sendmsgBtnLogin mobMT20 mobMAuto linehtN"
                onClick={createContactInfo}
              >
                {"Confirm"}
              </Button>
              <Box className="DF JCSB PT10">
                <Box
                  className="cursorPointer hoverUnderline"
                  onClick={backToLogin}
                >
                  Back to Login
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}
