import {
  Box,
  Typography,
  Checkbox,
  // InputAdornment,
  // IconButton,
  // Tooltip,
} from "@mui/material";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import { TextField, Button } from "@mui/material";
// import ReCaptchaV2 from "react-google-recaptcha";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { postMethodApi } from "../../Utils/Methods";
import { POST_RESEND_OTP, POST_SIGN_UP } from "../../Apis/EndPoints";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
// import InfoIcon from "@mui/icons-material/Info";
import Captcha from "../Captcha/Captcha";

export default function SignUp({
  toggle,
  setToggle,
  signUpScreen,
  setSignUpScreen,
  setLoginScreen,
  setOtpConfirmScreen,
}) {
  const [reRender, setRerender] = useState(true);
  // const [showNewPassword, setShowNewPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [showTooltip, setShowTooltip] = useState(false);
  const [switchBTNSignUp, setSwitchBtnSignUp] = useState(true);
  const [signUpForm, setSignUpForm] = useState({
    name: "",
    company: "",
    emailid: "",
    message: "",
    phone: "",
    ip_address: "",
    checkboxCookie: false,
    password: "Abc@12345",
    confirmPassword: "",
  });
  const captchaRef = useRef();
  const isClicked = useRef(false);
  // const passwordInfo = `Password should contain minimun 8 characters!
  //                       Password should have atleast 1 uppercase letter
  //                       Password should have atleast 1 special Character
  //                       Password should have atleast 1 number`;

  // const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  // const handleClickShowConfirmPassword = () =>
  //   setShowConfirmPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  const handleChange = (event) => {
    const sanitizedValue = DOMPurify.sanitize(event.target.value);
    setSignUpForm({
      ...signUpForm,
      [event.target.name]: sanitizedValue,
    });
  };

  // const toolTipTimer = () => {
  //   setShowTooltip(true);
  //   setTimeout(() => setShowTooltip(false), 4000);
  // };

  const resendOTP = () => {
    postMethodApi(POST_RESEND_OTP, {
      email: localStorage.getItem("emailForOTP"),
    })
      .then((res) => {
        if (res.status === 200) {
          setSwitchBtnSignUp(true);
          toast.success("Please enter the OTP");
          // setToggle(false);
          setSignUpForm({
            name: "",
            company: "",
            emailid: "",
            phone: "",
            message: "",
            checkboxCookie: false,
            ip_address: localStorage.getItem("IPAddress"),
            password: "Abc@12345",
            confirmPassword: "",
          });
          setOtpConfirmScreen(true);
          setRerender(false);
          setTimeout(() => {
            setRerender(true);
          }, 0);
          setSignUpScreen(false);
        }
      })
      .catch((error) => {
        toast.error("Some error occur ");
        return;
      });
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return email.trim().length > 0 && emailRegex.test(email) && !email.includes(",") && !email.includes(" ");
  }

  const CreateSignUp = (isValid) => {
    /* eslint-disable */
    // const hasNumber = /\d/;
    // const hasUpperCase = /[A-Z]/;
    // const hasSpecialCase = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    /* eslint-enable */

    signUpForm.ip_address = localStorage.getItem("IPAddress");
    
    if (signUpForm.name.trim() === "") {
      toast.error("Name is required ");
      return;
    }
    
    if (!isValidEmail(signUpForm.emailid)) {
      toast.error("Enter Correct Email Address ");
      return;
    }

    // if (signUpForm.emailid.trim() === "") {
    //   toast.error("Email is required ");
    //   return;
    // }

    // if (
    //   !signUpForm.emailid.split("").includes("@") ||
    //   !signUpForm.emailid.split("").includes(".") ||
    //   signUpForm.emailid.includes(" ")
    // ) {
    //   toast.error("Enter Correct Email Address ");
    //   return;
    // }

    // if (signUpForm.password.trim() === "") {
    //   toolTipTimer();
    //   return;
    // }
    // if (signUpForm.password.length < 8) {
    //   toolTipTimer();
    //   return;
    // }
    // if (!hasNumber.test(signUpForm.password)) {
    //   toolTipTimer();
    //   return;
    // }
    // if (!hasSpecialCase.test(signUpForm.password)) {
    //   toolTipTimer();
    //   return;
    // }
    // if (!hasUpperCase.test(signUpForm.password)) {
    //   toolTipTimer();
    //   return;
    // }

    // if (signUpForm.confirmPassword !== signUpForm.password) {
    //   toast.error("Confirm password is not same");
    //   return;
    // }

    if (!isValid) {
      toast.error("Invalid Captcha");
      return;
    }

    if (
      signUpForm.checkboxCookie === "" ||
      signUpForm.checkboxCookie === undefined ||
      signUpForm.checkboxCookie === false
    ) {
      toast.error("Please accept Terms of Usage ");
      return;
    }

    if (!isClicked.current) {
      isClicked.current = true;
      postMethodApi(POST_SIGN_UP, signUpForm)
        .then((res) => {
          if (res.status === 201) {
            localStorage.setItem("emailForOTP", signUpForm.emailid);
            toast.success("Please enter the OTP");
            // setSwitchBtnSignUp(true);
            // setToggle(false);
            setSignUpScreen(false);
            setSignUpForm({
              name: "",
              company: "",
              emailid: "",
              phone: "",
              message: "",
              checkboxCookie: false,
              ip_address: localStorage.getItem("IPAddress"),
              password: "Abc@12345",
              confirmPassword: "",
            });
            setOtpConfirmScreen(true);
            setRerender(false);
            setTimeout(() => {
              setRerender(true);
            }, 0);
          }
          if (res.status === 400) {
            toast.error(res.data?.message);
          }
          if (res.status === 409) {
            localStorage.setItem("emailForOTP", signUpForm.emailid);
            toast.warn("Email already exist Re-Send the OTP");
            setSwitchBtnSignUp(false);
          }
          if (res.status === 410) {
            toast.warn("Email already exist please login");
            setSignUpScreen(false);
            setLoginScreen(true);
          }
        })
        .catch((error) => {
          toast.error("Some error occur ");
          return;
        })
        .finally(() => {
          isClicked.current = false; // Reset the flag after the API call completes
        });
    }
  };

  const handleCaptchaValidation = (isValid) => {
    // if (isValid) {
    //   CreateSignUp();
    // } else {
    //   toast.error("Invalid Captcha");
    // }
    CreateSignUp(isValid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    captchaRef.current.validateCaptcha();
  };

  return (
    <Box
      className="loginContainerConfirmOTPScreen DF JCC AIC"
      display={toggle && signUpScreen ? "" : "none"}
    >
      {reRender ? (
        <Box className="boxshadow4sides bordr10px signUpForm P2 PB1">
          <Box>
            <Box className="DF JCSB">
              <Typography variant={"h1"} className="contactusformheading">
                {"Sign Up"}
              </Typography>
              <Box
                className="cursorPointer"
                onClick={() => {
                  setSignUpForm({
                    name: "",
                    company: "",
                    emailid: "",
                    phone: "",
                    message: "",
                    checkboxCookie: false,
                    ip_address: localStorage.getItem("IPAddress"),
                    password: "Abc@12345",
                    confirmPassword: "",
                  });
                  setRerender(false);
                  setSwitchBtnSignUp(true);
                  setTimeout(() => {
                    setRerender(true);
                  }, 0);
                  setToggle(false);
                  setSignUpScreen(false);
                }}
              >
                <CloseIcon />
              </Box>
            </Box>

            {/* <Box className="DF JCSB AIC">
              <Typography variant={"h6"} className="loginTextSubHeading">
                {"Enter details for new user"}
              </Typography>

              <Tooltip
                title={passwordInfo}
                open={showTooltip}
                // placement="top-start"
                className="cursorPointer"
                onClick={() => {
                  setShowTooltip(!showTooltip);
                  setTimeout(() => setShowTooltip(false), 4000);
                }}
              >
                <InfoIcon sx={{ m: 1, color: "#898887" }} />
              </Tooltip>
            </Box> */}

            <Box sx={{ mt: 3.25 }} className="DF JCSB FWW getintouchinputfield">
              <TextField
                name="name"
                label="Name (Required)"
                className="accordionbggrey textFieldStyleSignUp"
                sx={{ color: "#636363" }}
                size="small"
                InputProps={{
                  style: {
                    height: "50px",
                    color: "#636363",
                  },
                }}
                onChange={handleChange}
                value={signUpForm.name}
              />

              <TextField
                name="company"
                label="Company (Optional)"
                className="accordionbggrey textFieldStyleSignUp"
                sx={{ color: "#636363" }}
                size="small"
                InputProps={{
                  style: {
                    height: "50px",
                    color: "#636363",
                  },
                }}
                onChange={handleChange}
                value={signUpForm.company}
              />

              <TextField
                name="emailid"
                label="Email (Required)"
                className="accordionbggrey textFieldStyleSignUp"
                sx={{ color: "#636363" }}
                size="small"
                InputProps={{
                  style: {
                    height: "50px",
                    color: "#636363",
                  },
                }}
                onChange={handleChange}
                value={signUpForm.emailid}
              />

              <TextField
                name="phone"
                label="Phone (Optional)"
                type="number"
                className="accordionbggrey textFieldStyleSignUp"
                sx={{ color: "#636363" }}
                size="small"
                InputProps={{
                  style: {
                    height: "50px",
                    color: "#636363",
                  },
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                onChange={handleChange}
                value={signUpForm.phone}
              />

              {/* <TextField
                name="password"
                label="New Password"
                type={showNewPassword ? "text" : "password"}
                className="accordionbggrey textFieldStyleSignUp"
                sx={{ color: "#636363" }}
                size="small"
                InputProps={{
                  style: {
                    height: "50px",
                    color: "#636363",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                value={signUpForm.password}
              />

              <TextField
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                className="accordionbggrey textFieldStyleSignUp"
                sx={{ color: "#636363" }}
                size="small"
                InputProps={{
                  style: {
                    height: "50px",
                    color: "#636363",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChange}
                value={signUpForm.confirmPassword}
              /> */}
            </Box>

            <TextField
              name="message"
              label="Propose (Optional)"
              className="accordionbggrey textfieldstylefull"
              id="outlined-multiline-static linehtN"
              multiline
              rows={4}
              size="small"
              sx={{ height: 100, color: "#636363" }}
              InputProps={{
                style: {
                  height: "100px",
                  color: "#636363",
                },
              }}
              onChange={handleChange}
              value={signUpForm.Message}
            />

            {/* <Box className="reCaptcha">
              <ReCaptchaV2
                // sitekey={"6LfoBRUgAAAAAL6e1-thG56JCxpCRfDs_r5zoDry"}
                sitekey={"6Ld5c_UoAAAAAHqS4gazOrVdYHcxJ6t5K-9MfKfY"}
                // onChange={handleToken}
                // onExpire={handleExpire}
                className="MB20"
                ref={captchaRef}
              />
            </Box> */}

            <Captcha
              ref={captchaRef}
              onValidate={handleCaptchaValidation}
              lable={"Enter Captcha"}
              // helpText={"Invalid Captcha"}
            />

            <Box component={"div"} className="DF AIC MB20 verySmallMobMB10">
              <Checkbox
                style={{ padding: "2px 1rem 0px 0rem" }}
                className=""
                name="checkboxCookie"
                defaultChecked={false}
                value={signUpForm.checkboxCookie}
                onChange={handleChange}
              ></Checkbox>
              <Typography className="Contactustermsofusage linehtN colorBlack">
                {"I accept Indxx's terms of usage, acknowledge the "}
                <Link to={"/data-privacy"} target="_blank" className="colorBlack hoverUnderline">
                  {"Data Privacy"}
                </Link>
                {" Policy, and authorize to contact."}
              </Typography>
            </Box>

            <Box className="DF JCSB FWW getintouchinputfield">
              <Button className="sendmsgBtn linehtN" onClick={handleSubmit}>
                {"Submit"}
              </Button>

              <Button
                disabled={switchBTNSignUp}
                className={`${
                  switchBTNSignUp ? "sendResendOTPBtn" : "sendmsgBtn"
                } linehtN`}
                onClick={resendOTP}
              >
                {"Re-Send OTP"}
              </Button>
            </Box>

            <Box className="DF JCSB PT10">
              <Box
                className="cursorPointer hoverUnderline"
                onClick={() => {
                  setSignUpForm({
                    name: "",
                    company: "",
                    emailid: "",
                    phone: "",
                    message: "",
                    checkboxCookie: false,
                    ip_address: localStorage.getItem("IPAddress"),
                    password: "Abc@12345",
                    confirmPassword: "",
                  });
                  setRerender(false);
                      setTimeout(() => {
                        setRerender(true);
                      }, 0);
                  setSignUpScreen(false);
                  setLoginScreen(true);
                }}
              >
                Already have an account? Login
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
}
